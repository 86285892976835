import React, { useState } from "react";
import { Form, Input, Button, message, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import {
  openNotificationWithIcon,
  validatePhoneNumber,
} from "../../utils/helper";
import { resetPassword } from "../../api/base/base";
import { nanoid } from "nanoid";
import Inputwithaddon from "../../components/inputwithaddon/inputwithaddon";

const Resetpassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Email",
      active: true,
    },
    {
      id: nanoid(),
      name: "Phone",
      active: false,
    },
  ]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    temporaryPin: "",
    newPin: "",
    confirmPin: "",
    phone: "",
    email: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);

    form.setFieldsValue({
      email: undefined,
    });
    form.setFieldsValue({
      phone: undefined,
    });
  };

  const onFinish = async (values: any) => {
    const { temporaryPin, newPin, confirmPin, phone, email } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (tabs[0].active) {
      newErrors["phone"] = "";
    } else {
      if (!validatePhoneNumber(`+234${phone}`)) {
        newErrors["phone"] = "Invalid phone number";
      } else {
        newErrors["phone"] = "";
      }
    }

    if (newPin !== confirmPin) {
      newErrors["confirmPin"] = "PIN and Confirm PIN must be the same";
      newErrors["newPin"] = "PIN and Confirm PIN must be the same";
    } else {
      newErrors["confirmPin"] = "";
      newErrors["newPin"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await resetPassword({
          current_pin: temporaryPin,
          new_pin: newPin,
          loginid: tabs[0].active ? email : `0${phone}`,
        });

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Reset Password", message);

          navigate("/signin");
        } else {
          setError(message);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error("Something went wrong: Sign up");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="pb-16 mt-4 mb-16">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className="w-30" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Check Your Inbox
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        A temporary PIN has been sent to your Registered Email/Phone Number,
        Kindly input and update your new PIN to retrieve your account.
      </p>

      <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-11/12  lg:w-3/12 mx-auto mb-6">
        {tabs.map((item, index) => (
          <div
            className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
              item.active ? "bg-appcolorwhite rounded-xl" : "bg-transparent"
            }`}
            onClick={() => {
              if (!loading) {
                switchTabs(index);
              }
            }}
            key={item.id}
          >
            {item.name}
          </div>
        ))}
      </div>

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          {tabs[0].active ? (
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid Email",
                },
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          ) : (
            <Form.Item
              validateStatus={errors?.phone.length > 0 ? "error" : undefined}
              help={errors?.phone.length > 0 ? errors.phone : undefined}
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
              ]}
            >
              <Inputwithaddon
                updateField={updateField}
                name="phone"
                placeHolder="8000000000"
                addonBefore="+234"
              />
            </Form.Item>
          )}

          <Form.Item
            label="Enter Temporary PIN"
            name="temporaryPin"
            rules={[
              {
                required: true,
                message: "Temporary PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="Temporary PIN" />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.newPin.length > 0 ? "error" : undefined}
            help={errors?.newPin.length > 0 ? errors.newPin : undefined}
            label="Enter New PIN"
            name="newPin"
            rules={[
              {
                required: true,
                message: "New PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="New PIN" />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.confirmPin.length > 0 ? "error" : undefined}
            help={errors?.confirmPin.length > 0 ? errors.confirmPin : undefined}
            label="Confrim PIN"
            name="confirmPin"
            rules={[
              {
                required: true,
                message: "Confirm PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="Confirm PIN" />
          </Form.Item>

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto my-8 mb-4">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <p className="text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey">
        Have an account?
        <span
          className="pl-1 cursor-pointer text-appcolorblue"
          onClick={() => {
            if (!loading) {
              navigate("/signin");
            }
          }}
        >
          Sign in
        </span>
      </p>
    </div>
  );
};

export default Resetpassword;
