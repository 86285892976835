import React, { useState } from "react";
// import { EyeIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";
import Uploadsignaturemodal from "../../../components/uploadsignaturemodal/uploadsignaturemodal";
//@ts-ignore
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button, Modal } from "antd";
import { current } from "@reduxjs/toolkit";

const navlist = [
  {
    name: "Fair Lending Policy",
    text: "Kiowope Fair Lending Policy",
    link: "/dashboard/documents",
    docLink: "/docs/Fair-Lending-Policy.pdf",
  },
  {
    name: "Privacy Policy",
    text: "Kiowope Privacy Policy",
    link: "/dashboard/documents",
    docLink: "/docs/Privacy-Policy.pdf",
  },
  {
    name: "Terms of  Use",
    text: "Kiowope Terms of  Use",
    link: "/dashboard/documents",
    docLink: "/docs/Terms-of-Use.pdf",
  },
  {
    name: "eSign Consent ",
    text: "Your Consent to receive vital documentation via electronic means",
    link: "/dashboard/documents",
    docLink: "/docs/eSig-Consent.pdf",
  },
  // {
  //   name: 'Service Level Agreement',
  //   text: 'Your Agreement with Health Service Provider.',
  //   link: '/dashboard/documents',
  // },
  {
    name: "Next of Kin / Guarantors Form",
    text: "Your 2nd Tier Guarantors Details and agreement ",
    link: "/dashboard/relationship",
  },

  {
    name: "Your Signature",
    text: "Your e-Signature as appended and to be appended on all documents",
    link: "/dashboard/documents",
  },
];

const Documents = () => {
  const navigate = useNavigate();

  const [currentDoc, setCurrentDoc] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleUploadSignature, setVisibleUploadSignature] = useState(false);

  const closeModal = () => {
    setVisibleUploadSignature(false);
  };

  return (
    <div className="mt-2">
      {/* <div className="flex justify-center ">
        <img src="/img/logo/newlogo.svg" alt="logo" className='w-16' />
      </div>

       */}

      <div className="w-11/12 mx-auto  lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Documents
          </h1>
        </div>

        <div>
          {navlist.map((item) => (
            <div
              className="flex mb-8 cursor-pointer"
              key={item.name}
              onClick={() => {
                if (item.docLink !== undefined) {
                  setVisible(true);
                  setCurrentDoc(item.docLink);
                } else {
                  if (item.name === "Your Signature") {
                    setVisibleUploadSignature(true);
                  } else {
                    navigate(item.link);
                  }
                }
              }}
            >
              <div className="w-12 h-12">
                <img src="/img/vectors/downdocs.svg" alt="icon" />
              </div>

              <div className="ml-2 grow">
                <h2 className="text-base font-semibold font-ibmplexsans text-appcolorblack">
                  {item.name}
                </h2>
                <span className="text-xs text-appcolorblack">{item.text}</span>
              </div>

              {/* <div className='w-6 h-6 ml-8'>
                  <EyeIcon className='text-appcolorblue' />
                </div> */}
            </div>
          ))}
        </div>
      </div>

      {visibleUploadSignature && (
        <Uploadsignaturemodal
          visible={visibleUploadSignature}
          closeModal={closeModal}
        />
      )}

      {visible && currentDoc !== "" && (
        <Modal
          open={visible}
          footer={null}
          onCancel={() => {
            setVisible(false);
          }}
          onOk={() => {
            setVisible(false);
          }}
        >
          <div className="mb-4">
            <Button>
              <a href={currentDoc} download>
                Download Document
              </a>
            </Button>
          </div>
          <DocViewer
            documents={[
              {
                uri: currentDoc,
              },
            ]}
            activeDocument={{
              uri: currentDoc,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Documents;
