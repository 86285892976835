import React, { useState } from "react";
import { CreatecardInterface } from "./cards-interface";
import { Alert, Button, Form, Modal, message } from "antd";
import Inputselect from "../../../components/inputselect/inputselect";
import { addCard } from "../../../api/card/card";
import { openNotificationWithIcon } from "../../../utils/helper";

const Createcard = ({
  visible,
  closeModal,
  setPaymentLink,
  setShowPaymentModal,
}: CreatecardInterface) => {
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const onFinish = async () => {
    try {
      setError(null);
      setSubmitting(true);
      const res = await addCard({
        currency: "ngn",
        // amount: '500'
      });
      const { status, data } = res.data;

      if (status === "success") {
        // openNotificationWithIcon("success", "Add Card", message);

        setPaymentLink(data);
        setShowPaymentModal(true);

        closeModal();
      } else {
        setError(res.data.message);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      message.error("Something went wrong: Initiate add card");
    }
  };

  const updateField = (name: string, value: string): void => {};

  return (
    <div>
      <Modal
        onCancel={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        onOk={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Link Card
            </h1>
          </div>

          {error != null && (
            <div className="mb-2">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}
        </div>

        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          initialValues={{
            currency: "NGN",
          }}
        >
          <p className="my-4">
            By clicking on Submit Button below, you agreeing to add your payment
            card to Moveon for auto debit.
          </p>
          {/* <Form.Item
            name="currency"
            rules={[
              {
                required: true,
                message: "Currency is required",
              },
            ]}
          >
            <Inputselect
              placeHolder="Choose Bank"
              updateField={updateField}
              name={"bank"}
              selectOptions={["NGN"]}
              optionHasValue={true}
              loading={false}
              showSearch={true}
              disabled
              selectValue="NGN"
            />
          </Form.Item> */}

          <Form.Item>
            <Button
              htmlType="submit"
              className="mb-2 "
              disabled={submitting}
              loading={submitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Createcard;
