import React, { useEffect } from "react";

const emailAddress = "https://kiowope.money/";

const Support = () => {
  useEffect(() => {
    // Load and initialize Tawk_API here
    //@ts-ignore
    const Tawk_API = window?.Tawk_API || {};
    Tawk_API.onLoad = () => {
      // Code to run when Tawk_API is loaded
      // console.log("Tawk_API loaded");
    };

    // Replace 'your-property-id' with your actual Tawk.to property ID
    const Tawk_LoadStart = new Date();
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
		s1.src='https://embed.tawk.to/664f6510981b6c564773a636/1huj25itj';
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode?.insertBefore(s1, s0);


    // Cleanup function
    return () => {
      // Cleanup or perform actions when the component is unmounted
      // For example, you might want to hide the Tawk.to widget
      //@ts-ignore
      if (window?.Tawk_API) {
        //@ts-ignore
        window?.Tawk_API.hideWidget();
      }
    };
  }, []);

  return (
    <div>
      <h1 className="mb-2 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Tell us how we can help 👋
      </h1>

      <p className="w-11/12 mx-auto mb-6 text-sm font-normal text-center font-poppins text-appcolorgrey">
        Our super trained and compentent team are
        <br />
        standing by for services and support.
      </p>

      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            // const myButton = document.getElementById("tawk-bubble-container");
            // console.log(myButton)
            // if (myButton !== undefined && myButton !== null) {
            //   myButton.click();
            // }
            if (window !== undefined && window !== null) {
              //@ts-ignore`
              window?.Tawk_API.toggle();
            }
          }}
        >
          <div className="mr-3">
            <img src="/img/vectors/chatting.svg" alt="chatting" />
          </div>

          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Chat
            </h1>
            <p className="text-sm text-appcolorlightharsh">
              Start a conversation now.
            </p>
          </div>
        </div>

        {/* <div className='flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh'
          onClick={() => {
            window.open('https://kiowope.money/', '_blank');
          }}
        >
          <div className='mr-3'>
            <img src="/img/vectors/faq.svg" alt="faq" />
          </div>

          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              FAQ
            </h1>
            <p className='text-sm text-appcolorlightharsh'>
              Get intelligent answers instantly
            </p>
          </div>
        </div> */}

        {/* <div className='flex items-start px-8 py-3 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh'
          onClick={() => {
            window.location.href = `mailto:${emailAddress}`;
          }}
        >
          <div className='mr-3'>
            <img src="/img/vectors/envelopeplus.svg" alt="envelopeplus" />
          </div>

          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Email
            </h1>
            <p className='text-sm text-appcolorlightharsh'>
              Get a solution sent to you by email in superb time
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Support;
