import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "antd";
import { openNotificationWithIcon } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

const Offlineviewscreen = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-2">
      <div className="w-11/12 h-screen mx-auto lg:w-3/12">
        <div className="flex flex-col items-center justify-center h-full my-auto mt-2">
          <div className="w-11 h-11">
            <ExclamationCircleIcon className="cursor-pointer" />
          </div>
          <p className="text-center cursor-pointer font-poppins">
            You need an active internet
          </p>
          <p className="text-center cursor-pointer font-poppins">
            connection to access Moveon.
          </p>

          <Button
            htmlType="submit"
            className="mt-2"
            onClick={() => {
              if (navigator.onLine === true) {
                navigate("/");
              } else {
                openNotificationWithIcon(
                  "warning",
                  "Offline",
                  "Make sure Wi-Fi or cellular data is turned on, then try again."
                );
              }
            }}
          >
            Try Again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Offlineviewscreen;
