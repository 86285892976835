import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { walletcardInterface } from "./walletcard-interface";
import Addmoney from "../addmoney/addmoney";
import { useNavigate } from "react-router-dom";
import { commaNumber, transformCurrencyToSymbol } from "../../utils/helper";
import {
  ArrowTopRightOnSquareIcon,
  BuildingLibraryIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const Walletcard = ({
  isloanCard,
  availableBalance,
  currency,
  name,
  loans,
  updateViewWalletDetails,
  amountCollected,
  repaidAmount,
  totalSaved,
  totalSavedWithInterest,
  isSavingsCard,
  totalInvested,
  totalInvestedWithInterest,
  isInvestmentCard,
}: walletcardInterface) => {
  const navigate = useNavigate();
  const [viewAmount, setViewAmount] = useState(true);
  const [visibleAddMoney, setVisibleAddMoney] = useState(false);

  const closeAddMoney = () => {
    setVisibleAddMoney(false);
  };

  const toggleViewAmount = () => {
    setViewAmount(!viewAmount);
  };

  return (
    <>
      <div
        className={`${
          amountCollected !== undefined && repaidAmount !== undefined
            ? "h-40"
            : "h-48"
        } rounded-lg w-[99%] p-4 font-poppins ${
          isloanCard ? "bg-appcolorblue" : "bg-appcolorblue "
        }`}
      >
        <>
          <div className="flex items-baseline justify-between mb-2 lg:flex-row">
            <p
              className={`text-base ${
                isloanCard ? "text-appcolorwhite" : "text-appcolorwhite"
              } mb-2`}
            >
              {isloanCard
                ? "Recent Loan Details"
                : isInvestmentCard
                ? "Recent Investment Details"
                : isSavingsCard
                ? "Recent Savings Details"
                : "Wallet Balance"}
            </p>

            {isInvestmentCard || isSavingsCard ? null : (
              <>
                {!isloanCard ? (
                  <>
                    <div>
                      {/* <span
                className="px-2 py-1 mr-2 text-xs font-black cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                onClick={() => {
                  if (updateViewWalletDetails !== undefined) {
                    updateViewWalletDetails(true);
                  }
                }}
              >
                View Virtual Account Details
              </span> */}
                      <span
                        className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                        onClick={() => {
                          // setVisibleAddMoney(true);
                          navigate("/dashboard/add-money");
                        }}
                      >
                        ADD MONEY
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {loans !== undefined && loans?.length > 0 && (
                      <span className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorred text-appcolorwhite">
                        OVERDUE
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>

        {totalInvested !== undefined &&
        totalInvestedWithInterest !== undefined ? (
          <>
            <div className="">
              <p className="text-appcolorwhite">
                Total Invested: {currency}
                {commaNumber(totalInvested)}
              </p>

              <p className="text-appcolorwhite">
                Invested With Interest: {currency}
                {commaNumber(totalInvestedWithInterest)}
              </p>
            </div>
          </>
        ) : (
          <>
            {totalSaved !== undefined &&
            totalSavedWithInterest !== undefined ? (
              <>
                <div className="">
                  <p className="text-appcolorwhite">
                    Total Saved: {currency}
                    {commaNumber(totalSaved)}
                  </p>

                  <p className="text-appcolorwhite">
                    Saved With Interest: {currency}
                    {commaNumber(totalSavedWithInterest)}
                  </p>
                </div>
              </>
            ) : (
              <>
                {amountCollected !== undefined && repaidAmount !== undefined ? (
                  <>
                    <div className="">
                      <p className="text-appcolorwhite">
                        Amount Collected: {currency}
                        {commaNumber(amountCollected)}
                      </p>

                      <p className="text-appcolorwhite">
                        Amount To Be Paid: {currency}
                        {commaNumber(repaidAmount)}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`flex ${
                        viewAmount ? "items-baseline" : "items-center"
                      }`}
                    >
                      <span className="mb-0 mr-2 text-2xl text-appcolorwhite">
                        {viewAmount
                          ? `${currency?.toLocaleUpperCase()} ${commaNumber(
                              availableBalance
                            )}`
                          : "*********"}
                      </span>
                      {viewAmount ? (
                        <EyeOutlined
                          className="cursor-pointer text-appcolorwhite"
                          onClick={() => {
                            toggleViewAmount();
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          className="cursor-pointer text-appcolorwhite"
                          onClick={() => {
                            toggleViewAmount();
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}

        {/* {isSavingsCard || isInvestmentCard || isloanCard ? null : (
          <>
            {currency?.toLowerCase() === "ngn" && (
              <div className="flex justify-between gap-2 pt-10">
                <div className="flex gap-2">
                  <span
                    className="px-1 py-1 text-xs font-black text-center cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                    onClick={() => {
                      // setVisibleAddMoney(true);
                      navigate("/dashboard/transfer/internal");
                    }}
                  >
                    Transfer to MoveOn User
                  </span>

                  <span
                    className="px-1 py-1 text-xs font-black text-center cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                    onClick={() => {
                      // setVisibleAddMoney(true);
                      navigate("/dashboard/transfer/external");
                    }}
                  >
                    Withdraw to Bank
                  </span>
                </div>

                <span
                  className="px-1 py-1 text-xs font-black text-center cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                  onClick={() => {
                    navigate("/dashboard/auto-payout");
                  }}
                >
                  Auto Payout
                </span>
              </div>
            )}
          </>
        )} */}

        {isloanCard ? (
          <>
            {availableBalance !== 0 ? (
              <>
                {loans?.length !== 0 ? (
                  <>
                    <p className="mb-4 text-sm font-black text-appcolorred">
                      NEXT PAYMENT IS OVERDUE
                    </p>
                    <div className="flex">
                      {/* <div className='mr-2'>
                      <Button
                        htmlType="submit"
                        className='mb-2'
                      >
                        VIEW DETAILS
                      </Button>
                    </div> */}
                      <div>
                        <Button
                          htmlType="submit"
                          className="mb-2"
                          onClick={() => {
                            navigate("/dashboard/repay");
                          }}
                        >
                          REPAY NOW
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="mb-4 text-sm font-black text-appcolorwhite">
                    NO CURRENT LOAN
                  </p>
                )}
              </>
            ) : null}
          </>
        ) : null}

        {visibleAddMoney && (
          <Addmoney visible={visibleAddMoney} closeModal={closeAddMoney} />
        )}
      </div>

      {currency?.toLowerCase() === "ngn" && (
        <div className="mt-2">
          <p className="text-lg font-medium b-0 font-poppins text-appcolorgrey">
            Transfer To
          </p>
          <div className="flex items-center justify-between mt-2 w-[90%] mx-auto">
            <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
                navigate("/dashboard/transfer/internal");
              }}
            >
              <div className="flex items-center justify-center w-12 h-12 p-2 bg-blue-300 rounded-md">
                <UserIcon className="text-blue-600 w-[1.5rem] h-[1.5rem]" />
              </div>
              <p className="mt-1 text-xs black center font-poppins">
                MoveOn Wallet
              </p>
            </div>

            <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
                navigate("/dashboard/transfer/external");
              }}
            >
              <div className="flex items-center justify-center w-12 h-12 p-2 bg-blue-300 rounded-md">
                <BuildingLibraryIcon className="text-blue-600 w-[1.5rem] h-[1.5rem]" />
              </div>
              <p className="mt-1 text-xs black center font-poppins">
                Other Banks
              </p>
            </div>

            <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
                navigate("/dashboard/auto-payout");
              }}
            >
              <div className="flex items-center justify-center w-12 h-12 p-2 bg-blue-300 rounded-md">
                <ArrowTopRightOnSquareIcon className="text-blue-600 w-[1.5rem] h-[1.5rem]" />
              </div>
              <p className="mt-1 text-xs black center font-poppins">
                Auto Payout
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Walletcard.defaultProps = {
  isloanCard: false,
  currency: "₦",
  name: "",
  availableBalance: 0,
  loans: [],
};

export default Walletcard;
